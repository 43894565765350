/*===========================
       COMMON css 
===========================*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:400,700&display=swap");
body {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #444;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  /* color: #fff; */
  margin: 0px;
}

h1 {
  font-size: 48px;
  color: #fff;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  margin: 0px;
}

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

/*===== All Button Style =====*/
.main-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #403d94;
  padding: 0 40px;
  font-size: 15px;
  line-height: 48px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -ms-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  background-color: #403d94;
  text-transform: uppercase;
}
.main-btn:hover {
  -webkit-box-shadow: 0px 10px 25px 0px rgba(61, 190, 238, 0.25);
  -moz-box-shadow: 0px 10px 25px 0px rgba(61, 190, 238, 0.25);
  box-shadow: 0px 10px 25px 0px rgba(61, 190, 238, 0.25);
  color: #fff;
}
.main-btn.main-btn-2 {
  background-color: #403d94;
  color: #fff;
  border-color: #403d94;
}
.main-btn.main-btn-2:hover {
  background-color: #e8550f;
  color: #fff;
  border-color: #e8550f;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/*===== All Slick Slide Outline Style =====*/
.slick-slide {
  outline: 0;
}

/*===== All Section Title Style =====*/
.section_title .sub_title {
  font-size: 18px;
  font-weight: 600;
  color: #403d94;
  text-transform: uppercase;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section_title .sub_title {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .section_title .sub_title {
    font-size: 16px;
  }
}

.section_title .main_title {
  font-size: 45px;
  font-weight: 600;
  color: #222;
  margin-top: 23px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section_title .main_title {
    font-size: 35px;
  }
}
@media (max-width: 767px) {
  .section_title .main_title {
    font-size: 28px;
  }
}

/*===== All Pagination Style =====*/
.pagination {
  padding-top: 50px;
}
@media (max-width: 767px) {
  .pagination {
    padding-top: 30px;
  }
}
.pagination .page-item {
  margin-right: 30px;
}
@media (max-width: 767px) {
  .pagination .page-item {
    margin-right: 15px;
  }
}
.pagination .page-item:last-child {
  margin-right: 0;
}
.pagination .page-item a {
  width: 45px;
  height: 45px;
  line-height: 43px;
  border: 1px solid #dadada;
  text-align: center;
  font-size: 18px;
  font-weight: 600px;
  color: #403d94;
  border-radius: 50%;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
@media (max-width: 767px) {
  .pagination .page-item a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
  }
}
.pagination .page-item a.active,
.pagination .page-item a:hover {
  background-color: #e8550f;
  border-color: #e8550f;
  color: #fff;
}

/*===== All Overlay Style =====*/
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.7);
  z-index: 99;
  opacity: 0;
  display: none;
}
.overlay.active {
  display: block;
  opacity: 1;
}

/*===== All Form Focus Style =====*/
.form-control {
  color: #444;
}
.form-control::placeholder {
  color: #444;
}
.form-control::-moz-placeholder {
  color: #444;
}
.form-control::-moz-placeholder {
  color: #444;
}
.form-control::-webkit-input-placeholder {
  color: #444;
}
.form-control:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: #dadada;
  color: #444;
}

/*===========================
       CATEGORY css 
===========================*/
.category_area {
  position: relative;
  z-index: 9;
}

.category_shape {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.single_category {
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  padding: 40px;
  border-radius: 5px;
  border: 1px solid transparent;
  color: #fff;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_category {
    padding: 30px 20px;
  }
}
@media (max-width: 767px) {
  .single_category {
    padding: 30px 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single_category {
    padding: 30px 15px;
  }
}
.single_category .category_icon i {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 46px;
  background-color: #fff;
  border-radius: 50%;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
@media (max-width: 767px) {
  .single_category .category_icon i {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 36px;
  }
}
.single_category .category_content {
  padding-top: 24px;
}
.single_category .category_content .category_title {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_category .category_content .category_title {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single_category .category_content .category_title {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .single_category .category_content .category_title {
    font-size: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single_category .category_content .category_title {
    font-size: 18px;
  }
}

.single_category .category_content .text i {
  margin-right: 5px;
}
.single_category .category_link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.single_category:hover {
  -webkit-box-shadow: 0px 15px 51px 0px rgba(255, 139, 33, 0.19);
  -moz-box-shadow: 0px 15px 51px 0px rgba(255, 139, 33, 0.19);
  box-shadow: 0px 15px 51px 0px rgba(255, 139, 33, 0.19);
  border-color: #e8550f;
  background-color: #e8550f;
}

.single_category .category_icon i {
  color: #e8550f;
}

.single_category:hover .category_icon i {
  background-color: #fff;
  color: #403d94;
}

.single_category {
  background-color: #403d94;
}

.single_category:hover .category_icon i {
  background-color: #403d94;
  color: #fff;
}

/*===========================
    ALL JOBS css 
===========================*/
.jobs_tabs_menu .nav {
  background-color: #fda172;
  border: 1px solid #e8550f;
  border-radius: 5px;
  padding: 5px;
}
.jobs_tabs_menu .nav .nav-item a {
  padding: 0 30px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  line-height: 45px;
  border-radius: 5px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .jobs_tabs_menu .nav .nav-item a {
    padding: 0 18px;
    font-size: 14px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .jobs_tabs_menu .nav .nav-item a {
    padding: 0 18px;
    font-size: 14px;
    line-height: 40px;
  }
}
.jobs_tabs_menu .nav .nav-item a.active {
  background-color: #e8550f;
  color: #fff;
}

.single_jobs {
  background-color: #fff;
  padding: 50px 25px;
  border: 2px solid #fff;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.single_jobs.jobs_border {
  border-color: rgba(61, 190, 238, 0.5);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_jobs {
    padding: 40px 20px;
  }
}
@media (max-width: 767px) {
  .single_jobs {
    padding: 40px 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single_jobs {
    padding: 20px 15px;
  }
}
.single_jobs .jobs_image img {
  width: 125px;
  border: 2px solid #3dbeee;
  border-radius: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_jobs .jobs_image img {
    width: 115px;
  }
}
@media (max-width: 767px) {
  .single_jobs .jobs_image img {
    width: 115px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single_jobs .jobs_image img {
    width: 85px;
  }
}
.single_jobs .jobs_content {
  padding-top: 25px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single_jobs .jobs_content {
    padding-top: 15px;
  }
}
.single_jobs .jobs_content .jobs_title a {
  font-size: 24px;
  font-weight: 600;
  /* color: rgb(250, 140, 140); */
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_jobs .jobs_content .jobs_title a {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .single_jobs .jobs_content .jobs_title a {
    font-size: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single_jobs .jobs_content .jobs_title a {
    font-size: 18px;
  }
}
.single_jobs .jobs_content .jobs_title a:hover {
  color: #403d94;
}
.single_jobs .jobs_content .sub_title {
  color: #403d94;
  margin-top: 8px;
}
.single_jobs .jobs_meta {
  padding-bottom: 15px;
  border-bottom: 1px solid #403d94;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single_jobs .jobs_meta {
    display: block !important;
  }
}
.single_jobs .jobs_meta p {
  font-size: 14px;
  padding-top: 15px;
  font-weight: 600;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single_jobs .jobs_meta p {
    padding-top: 5px;
  }
}
.single_jobs .jobs_meta p i {
  margin-right: 5px;
  color: #403d94;
}
.single_jobs .jobs_btn {
  padding-top: 30px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single_jobs .jobs_btn {
    padding-top: 15px;
  }
}
.single_jobs:hover {
  border-color: #403d94;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(61, 190, 238, 0.25);
  -moz-box-shadow: 0px 10px 20px 0px rgba(61, 190, 238, 0.25);
  box-shadow: 0px 10px 20px 0px rgba(61, 190, 238, 0.25);
}

/*===========================
      SUBSCRIBE css 
===========================*/
.subscribe_wrapper {
  padding-top: 0px;
  padding-bottom: 170px;
  position: relative;
  z-index: 9;
}

.subscribe_area {
  background-image: url(../staticImages/page_banner.jpeg);
  /* margin-bottom: 50px;
  margin-top: 0px; */
  background-repeat: no-repeat;
  background-size: cover;
  /* padding-top: 0px; */
  background-color: rgba(0, 0, 0, 0.5);
}
@media (max-width: 767px) {
  .subscribe_area .subscribe_wrapper {
    padding: 55px 15px 60px;
  }
}
.subscribe_area .subscribe_wrapper::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 165px;
  background-image: url(https://pxstores-frontend.netlify.app/images/subscribe-shape-1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  z-index: -1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe_area .subscribe_wrapper::before {
    height: 135px;
  }
}
.subscribe_area .subscribe_wrapper .subscribe_shape_1 {
  position: absolute;
  left: -148px;
  bottom: 0;
  z-index: -1;
}
@media (max-width: 767px) {
  .subscribe_area .subscribe_wrapper .subscribe_shape_1 {
    left: -110px;
  }
}
@media (max-width: 767px) {
  .subscribe_area .subscribe_wrapper .subscribe_shape_1 img {
    width: 110px;
  }
}
.subscribe_area .subscribe_wrapper .subscribe_shape_2 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
@media (max-width: 767px) {
  .subscribe_area .subscribe_wrapper .subscribe_shape_2 img {
    width: 110px;
  }
}
.subscribe_area .subscribe_wrapper .subscribe_form {
  position: relative;
  margin-top: 10px;
}
.subscribe_area .subscribe_wrapper .subscribe_form input {
  width: 100%;
  height: 20px;
  border: 2px solid #403d94;
  padding: 0 30px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #222;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.subscribe_area .subscribe_wrapper .subscribe_form input::placeholder {
  color: #fff;
}
.subscribe_area .subscribe_wrapper .subscribe_form input::-moz-placeholder {
  color: #fff;
}
.subscribe_area .subscribe_wrapper .subscribe_form input::-moz-placeholder {
  color: #fff;
}
.subscribe_area
  .subscribe_wrapper
  .subscribe_form
  input::-webkit-input-placeholder {
  color: #fff;
}
.subscribe_area .subscribe_wrapper .subscribe_form input:focus {
  border-color: #e8550f;
}
@media (max-width: 767px) {
  .subscribe_area .subscribe_wrapper .subscribe_form input {
    height: 60px;
    padding: 0 20px;
  }
}
.subscribe_area .subscribe_wrapper .subscribe_form .main-btn {
  height: 64px;
  line-height: 60px;
  position: absolute;
  top: 8px;
  right: 8px;
  -webkit-box-shadow: 0px 32px 29px 0px rgba(61, 190, 238, 0.3);
  -moz-box-shadow: 0px 32px 29px 0px rgba(61, 190, 238, 0.3);
  box-shadow: 0px 32px 29px 0px rgba(61, 190, 238, 0.3);
}
@media (max-width: 767px) {
  .subscribe_area .subscribe_wrapper .subscribe_form .main-btn {
    position: relative;
    height: 50px;
    line-height: 46px;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .subscribe_area .subscribe_wrapper .subscribe_form .main-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    margin-top: 0;
    width: auto;
    left: auto;
    padding: 0 25px;
  }
}

/*===========================
     OTHER css 
===========================*/
.single_work .work_icon i {
  font-size: 50px;
  color: #403d94;
  line-height: 50px;
}

.single_work .work_content .work_title {
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .single_work .work_content .work_title {
    font-size: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single_work .work_content .work_title {
    font-size: 24px;
  }
}

.single_work .work_content p {
  margin-top: 15px;
}

.vacant-positions {
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  color: #fff;
}

.fa-map-marker,
.fa-clock-o {
  color: #403d94 !important;
}

.jobs_image > img {
  border: 2px solid #403d94;
}

.country-job {
  /* height: 90px; */
  padding: 0 20px;
  border: 2px solid #403d94;
  border-radius: 20px;
  background-color: #fff;
  width: 700px;
  justify-self: center;
}
